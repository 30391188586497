<template>
<div
  class="leaflet-map"
  :class="{ printing }">
  <div
    :id="`${id}-map`"
    class="map-container"/>

  <div class="map-legend">
    <div
      v-for="l in layers"
      :key="l.key"
      class="legend-item">
      <StyleBox
        :type="l.type"
        v-bind="l.style"/>
      <div class="label">
        {{ l.label }}
      </div>
    </div>
</div>

</div>
</template>

<script>
import {
  map,
  tileLayer,
 // marker,
  geoJSON,
  circleMarker,
  //  featureGroup,
  control,
} from 'leaflet';

import StyleBox from './StyleBox';
//import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import { get } from '@/utils';
var m;

export default {
  name: 'LeafletMap',
  props: {
    id: {
      type: String,
      default: 'leaflet-map',
    },
    center: {
      type: Array,
      default: () => ([45.525, -122.625]),
    },
    viewport: {
      type: Object,
    },
    layers: {
      type: Array,
      default: ()=>([]),
    },
    zoom: {
      type: Number,
      default: 15,
    },
    points: {
      type: Array,
      default: () => [
        //  { label: 'Example Photo Point', lat: 45.525, lng: -122.625 }
      ],
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fitted: false,
      url: null,
      managedLayers: [],
      readyLayers: [],
      printing: false,
    };
  },
  components: {
    StyleBox,
  },
  created() {},
  mounted() {

    var el = document.getElementById(`${this.id}-map`);

    m = map(el, {
      zoomControl: false,
      // preferCanvas: true,
    });

    control.zoom({
      position: 'bottomright',
    }).addTo(m);

    m.on('moveend', this.mapMoveHandler);

    m.on('click', e => {
      this.$emit('click', e.latlng);
    })


    this.setTileLayer('satellite');

    this.addLayers();

    if(!this.fitted) {
      //console.log('about to set map center', this.center, this.zoom);
      m.setView(this.center, this.zoom);
      //m.fitBounds(this.center);
    }

  },
  watch: {
    center(a,b) {
      if(a[0] != b[0]) {
        console.log('center has changed', a, b);
        //this.setView(a, this.zoom);
      }
    },
    viewport(a,b) {
      console.log('viewport has changed', a, b);
      this.setView(a, this.zoom);
    }
  },
  computed: {
  },
  methods: {
    setView(center, zoom = null) {
      if(!zoom) {
        zoom = this.zoom;
      }
      console.log('setting map center', center, zoom);
      m.setView(center, zoom);
    },
    addLayers() {
      for(var i = 0; i<this.layers.length; i++) {
        let pointToLayer, style = this.layers[i].style;
        let label = this.layers[i].label;
        let visible = true;
        let key = this.layers[i].key || i;
        let type = this.layers[i].type;
        let editable = this.layers[i].editable || false;
        let tooltip = this.layers[i].tooltip || false;
        if(style && type == 'Point') {
          pointToLayer = (feature, latlng) => {
            return circleMarker(latlng, style)
          }
        }
        let lyr = geoJSON(this.layers[i], {
          style,
          pointToLayer,
          onEachFeature: (feature, layer ) => {
            if(tooltip) {
               layer.bindTooltip(feature.properties.label, {
                 permanent: true,
               })
             }
            if(editable) {
              layer.options.draggable = true;
              layer.on('moveend', e => {
                var latlng = e.target.getLatLng();
                var layer = key;
                return this.featureMoveHandler({ layer, feature, latlng })
              })
            }
            layer.on('mouseup', () => this.clickHandler({ layer: key, feature }))
          }
        }).addTo(m);
        let bnds = lyr.getBounds();
        //console.log(bnds);
        if(bnds.lat) {
          m.fitBounds(lyr.getBounds());
          this.fitted = true;
        }
        this.readyLayers.push(key);
        this.managedLayers.push({
          key, label, style, lyr, visible, editable, type
        })
      }
    },
    refreshLayers() {
      m.invalidateSize(); // this will fix the bounds after resizing
      this.fitted = false; // this will make sure that the first layer shifts the map when added
      this.managedLayers.map(l => m.removeLayer(l.lyr));
      this.readyLayers = [];
      this.managedLayers = [];
      this.addLayers();
    },
    setTileLayer(type) {
      var tiles;
      // streets
      // terrain
      // hybrid
      // satellite
      // open
      if(type == 'terrain') {
        tiles = tileLayer('https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
          maxZoom: 20,
          subdomains:['mt0','mt1','mt2','mt3']
        })
      } else if(type == 'streets') {
        tiles = tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
          maxZoom: 20,
          subdomains:['mt0','mt1','mt2','mt3']
        });
      } else if(type == 'hybrid') {
        tiles = tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',{
          maxZoom: 20,
          subdomains:['mt0','mt1','mt2','mt3']
        });
      } else if(type == 'satellite') {
        tiles = tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
          maxZoom: 20,
          subdomains:['mt0','mt1','mt2','mt3']
        });
      } else {
        tiles = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        })
      }
      // add to the map
      tiles.addTo(m);
    },
    refreshLayerData(layerKey) {
      let i = this.managedLayers.findIndex(d=>d.key==layerKey);
      let j = this.layers.findIndex(d=>d.key==layerKey);
      if(i >= 0 && j >=0 ) {
        let lyr = get([i, 'lyr'], this.managedLayers);
        // before we clear the layer we need to remove the tooltips
        lyr.eachLayer( layer => {
        //  console.log(layer)
          layer.unbindTooltip();
        });
        lyr.clearLayers();
        lyr.addData(this.layers[j])
        //console.log([...lyr.getLayers()])
      } else {
        console.log('could not find layer', layerKey, i, j);
      }
    },
    locationHandler(e) {
      console.log('location found', e)
    },
    clickHandler({ layer, feature }) {
      if(this.readyLayers.includes(layer)) {
        //console.log('click', feature, layer);
        this.$emit('click', { layer, feature });
      }
    },
    mouseHandler(e) {
      if(this.$attrs['onMouseover']) {
        console.log('mouse handler', e);
      }
    },
    mapMoveHandler(e) {
      //if(this.$attrs['onMove']) {
      this.$emit('map-moved', e);
     // }
    },
    featureMoveHandler({ layer, feature, latlng }) {
      if(this.readyLayers.includes(layer)) {
        this.$emit('feature-moved', { layer, feature, latlng });
      }
    },
    toUrl() {
      // const size = m.getSize();
      // const height = size.y;
      // const width = size.x;
      // return html2canvas(this.$el, {
      //   allowTaint:false,
      //   useCORS: true,
      //   width,
      //   height,
      // }).then(canvas => {
      //   var url = canvas.toDataURL('image/jpeg', 1.0);
      //   return url;
      // });
    },
    // pdf(doc, args = {}) {
    //   const size = m.getSize();
    //   const height = size.y;
    //   const width = size.x;
    //   this.printing = true;
    //   //var el = document.getElementById(`${this.id}-map`);
    //   return domtoimage(this.$el, {
    //     allowTaint:false,
    //     //allowTaint:true,
    //     useCORS: true,
    //     width,
    //     height,
    //   }).then(canvas => {
    //     const img = canvas.toDataURL('image/jpeg', 1.0);
    //     const format = 'JPEG';
    //     doc.addImagePage({ img, format, width, height, ...args });
    //     this.printing = false;
    //     return true;
    //   });
    // },
    pdf(doc, args = {}) {
      const size = m.getSize();
      const height = size.y;
      const width = size.x;
      this.printing = true;
      console.log(size, height, width)
      //var el = document.getElementById(`${this.id}-map`);
      return domtoimage.toPng(this.$el, {
        height,
        width,
      }).then(img => {
        const format = 'PNG';
        doc.addImagePage({ img, format, width, height, ...args });
        this.printing = false;
        return true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.leaflet-map  {
  position: relative;

  height: 100%;
  display: flex;
  flex-direction: column;
  .map-container {
    flex: 1;
    //border: 1px solid orange;
    //height: 100%;
  }

  min-height: 10rem;
  height: 100%;

  .map-legend {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    z-index: 750;
    background-color: rgba(255,255,255, 0.75);

    .legend-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.5rem;
      .label {
        margin-left: 0.5rem;
      }
    }
  }
}
</style>

<style lang="scss">
.leaflet-map  {
  &.printing {
    .leaflet-control-zoom {
      display: none;
    }
  }
}
</style>
