<template>
<div
  class="edit-form"
  :class="type">

  <input
    v-if="type!='memo'"
    :type="type"
    v-model="internalValue"
    :data-lpignore="true"
    :pattern="pattern"
    @keyup.enter.stop="updated">

  <textarea
    v-if="type == 'memo'"
    ref="textarea"
    v-model="internalValue"/>


  <div class="buttons padded">
    <Button
      type="cancel"
      @click="$emit('cancel')">
      cancel
    </Button>
    <Button
      type="save"
      @click="updated"
      :disabled="value == internalValue">
      save
    </Button>
  </div>

</div>
</template>
<script>
import Button from '@acorns-ui/Inputs/Button';

export default {
  name: 'EditForm',
  props: {
    value: {
      type: [String, Number],
    },
    type: {
      type: String,
      default: 'text',
    },
    pattern: {
      type: String,
    }
  },
  data() {
    return {
      internalValue: null,
    };
  },
  components: {
    Button,
  },
  created() {
    this.internalValue = this.value;
  },
  watch: {},
  computed: {},
  methods: {
    updated() {
      var value = this.internalValue;
      if(this.type == 'number') {
        value = value * 1;
      }
      this.$emit('update', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.edit-form  {
  //padding: 1rem;
  min-height: 4rem;
  min-width: 10rem;

  &.text {
    min-width: 30rem;
  }

  &.number {
    min-width: 10rem;
  }

  &.memo {
    width: 40rem;
    height: 40rem;
  }

  textarea {
    margin: 1rem 0.5rem;
    flex: 1;
  }

  input {
    //background-color: green;
    font-size: 1.25rem;
    padding: 0.25rem;
    height: 2.5rem;
    //width: calc(100% - 0.5rem);
    //height: calc(100% - 0.5rem);
    //border: 0;
    margin: 1rem 0.5rem;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 0.75rem;
  }
}
</style>
