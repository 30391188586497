<template>
  <div
    class="style-box"
    :style="{ 'border-color': color , opacity: strokeOpacity}">
    <div class="fill-mask">
      <div
        v-if="type == 'LineString'"
        class="fill-key">
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <line
            x1="0"
            y1="0"
            x2="16"
            y2="16"
            :stroke="color"
            :stroke-width="weight"
            :stroke-opacity="strokeOpacity"
            />
          <!-- If you do not specify the stroke
               color the line will not be visible -->
        </svg>
      </div>
      <div
        v-else-if="type == 'Point' && !fillColor"
        class="fill-key">
        <img
          src="https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png"/>
      </div>
      <div
        v-else
        class="fill-key"
        :style="{ borderColor: color, borderWidth: Math.ceil(weight) + 'px', borderStyle: 'solid' }">
        <div
          class="fill-color"
          :style="{ backgroundColor: fillColor, opacity: fillOpacity }"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StyleBox',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'polygon' // point, LineString
    },
    color: {
      type: String,
    },
    weight: {
      type: [String, Number],
    },
    fillColor: {
      type: String,
    },
    strokeOpacity: {
      type: [String, Number],
    },
    fillOpacity: {
      type: [String, Number],
    },
    small: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style lang="scss">
@import '~@/styles/variables';

.style-box {
  height: 1.25rem;
  width: 1.25rem;

  &.small {
    height: 1rem;
    width: 1rem;
  }

  .fill-mask {
    height: 100%;
    width: 100%;
    //background-color: white;
    .fill-key {
      height: 100%;
      width: 100%;
        img {
          height: 1.25rem;
          //width: 1.25rem;
        }
        .fill-color {
          height: 100%;
          width: 100%;
        }
    }
  }
}
</style>
